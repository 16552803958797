import domReady from '@wordpress/dom-ready';

domReady(() => {
  let hasAnimatedStats = false;

  function addClassOnScrollOrLoad() {
    const statValues = document.querySelectorAll('.highlighting-stats');

    function addClass() {
      statValues.forEach((statValue) => {
        const rect = statValue.getBoundingClientRect();
        if (window.innerWidth > 768) {
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            statValue.classList.add('animate');
          }
        } else if (rect.top >= 0) {
          statValue.classList.add('animate');
        }
      });
    }

    window.addEventListener('scroll', () => {
      addClass();
      if (!hasAnimatedStats) {
        animateStatValues();
        hasAnimatedStats = true;
      }
    });

    addClass();
  }

  function animateStatValues() {
    const statValues = document.querySelectorAll('.stat__value');

    statValues.forEach((statValue) => {
      const targetValue = parseFloat(statValue.textContent.replace(',', '.'));
      const isInteger = Number.isInteger(targetValue);
      let currentValue = isInteger ? 0 : 0.0;
      const incrementTime = 30;
      const animationDuration = 3000;
      const frames = animationDuration / incrementTime;
      const increment = (targetValue - currentValue) / frames;

      const updateValue = () => {
        if (
          (increment > 0 && currentValue < targetValue) ||
          (increment < 0 && currentValue > targetValue)
        ) {
          currentValue += increment;
          statValue.textContent = isInteger
            ? Math.round(currentValue)
            : currentValue.toFixed(2);
          setTimeout(updateValue, incrementTime);
        } else {
          statValue.textContent = isInteger
            ? targetValue.toFixed(0)
            : targetValue.toFixed(2);
        }
      };

      setTimeout(updateValue, incrementTime);
    });
  }

  window.addEventListener('load', () => {
    addClassOnScrollOrLoad();
  });
});
